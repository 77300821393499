






































import ShopAPI from "../../common/api/api_shop";
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import store from "../../store/shop";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component("TableList", {
  components: {
    SHIssueTicket: () =>
      import("../../components/compositions/SHIssueTicket.vue"),
    SHTableSkeleton: () =>
      import("../../components/components/SHTableSkeleton.vue"),
  },
  data() {
    return {
      isOpenPopupIssueTicket: false,
      tables: null,
      isAlreadyPooling: false,
      seatSelected: {
        id: null
      },
      loop: true,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.fetchTableList();
  },
  beforeDestroy() {
    this.loop = false;
  },
  watch: {
    onNotifyIot(newCount, oldCount) {
      // Our fancy notification (2).
      this.fetchTableList();
    }
  },
  computed: {
    onNotifyIot () {
      return store.state.pageChange.tableList;
    },
    tableList() {
      return this.tables;
    },
  },
  methods: {
    issueTicket: function (seat) {
      if (seat.is_available) {
        this.seatSelected = seat;
        this.isOpenPopupIssueTicket = true;
      }
    },
    issuedTicket: function() {
      this.closePopup();
      this.tables = null;
      this.fetchTableList();
    },
    closePopup: function () {
      this.isOpenPopupIssueTicket = false;
    },
    fetchTableList() {
      this.loading = true;
      api.tables_index().then((response) => {
        if (response.data.status === "success") {
          this.tables = response.data.data.tableList;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  filters: {},
});
